
#notistack-snackbar {
  font-size: 14px;
}
.embla {
  max-width: 48rem;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 25%;
}
.embla__viewport {
  overflow: hidden;
  padding-top: 5px;
}
.embla__container {
  display: flex;
  margin-left: -1rem;
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.embla__controls {
  display: flex;
  justify-content: center;

}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc(4 / 2 * -1);
  padding-bottom: 14px;
}
.embla__dot {
  -webkit-tap-highlight-color: rgba(49,49,49, 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width:12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  background-color:#D9D9D9 ;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  background-color: #B4B1B1;
}

.custom-checkbox-root .MuiSvgIcon-root{
  width: 18px;
  height: 18px;
}
.Logo_header a {
  text-decoration: none;
  color: #fff !important;
}
#coupon{
  .MuiDialog-container {
    height: 200px;
    outline: 0;
    position: absolute;
    inset: 0;
    top: unset;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  }
}
#user{
  .MuiDialog-container {
    height: unset;
    flex-grow: 1;
    outline: 0;
    position: absolute;
    inset: 0;
    top: unset;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
  }
}
a:hover {
  text-decoration: none;
}
@media screen and (max-width: 40em) {
  
  .responsiveTable{
     tr{
      background: #fff;
      border:none  !important;
      border-radius: 10px;
      padding: 10px!important;
      box-shadow: 1px 1px 5px #999;
      margin :10px 3px;
     }
   
    td.pivoted {
      text-align: right !important;
      padding-right: inherit;
      padding-left: inherit !important;
      display: flex;
      position: relative;
      padding: 10px;
      &:last-child{
        &:after{
          display: none;
        }
        >div{
          display: none;
        }
        >a{
          padding-left: 0 !important;
          display: block;
          flex: 1;
        }
      }
      &:after{
        
        content: '';
        position: absolute;
        display: inline-block;
        height: 1px;
        background-color: #D8D8D8;
        left: 12px;
        right: 12px;
        bottom: 0;
    }
      .tdBefore {
        color:#000;
        text-align: right !important;
        position: unset;
        display: block;
        width: inherit;
        white-space: inherit; 
        overflow-wrap: inherit;
        flex: 1;
      }
      span{
        display: block;
        flex: 1;
        padding-left: 0 !important;
      }
    }
  }
 }
.Login_button {
  border: 1px solid #fff !important;
  border-radius: 5px !important;
  color: #fff !important;
  font-size: 1.5rem !important;
  padding: 7px 17px 3px 17px;
}

.Shop_Cart {
  outline: none !important;
}

.NAV_Align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .Nav_search_Wrapper {
    margin: 0 10px;
  }
  .left-side-nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

nav {
  height: 50px;
}

.nav-icon {
  padding: 12px 8px !important;
}

.NAV_olText {
  margin: 0px !important;
  height: 100%;
  display: flex;
}

.Menu_Shop_cart {
  color: #fff !important;
  font-size: 25px !important;
}

.CheckOut_btn {
  background-color: #777 !important;
  border: 2px solid #777 !important;
  border-radius: 5px !important;
  color: #fff !important;
  padding: 5px !important;
  margin-right: 9px !important;
  font-size: 1.4rem !important;
}

/* Nav_HEader */

// @media (min-width: 768px) {
//   .dropdown_mega {
//     width: 1091px;
//   }
@media (min-width: 768px) {
  // .dropdown_mega {
  //   width: 100%;
  //   min-height: 200px;
  // }
}

ol {
  list-style-type: none;
  padding: 0;
}

ol li > .dropbtn {
  color: #fff;
  text-decoration: none;
}

ol li > .dropbtn:hover {
  text-decoration: none;
}

.Nav_HEader {
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.nav_align_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_align_item nav {
  margin-top: 8px;
}

/* Dropdown Button */
.dropbtn {
  color: white;
  transition: all 0.5s ease-in-out;
  padding: 7px 14px 7px 14px;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  text-transform: uppercase;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  margin-top: 10px;
  position: absolute;
  background-color: #00a693;
  border-radius: 0 0 3px 3px;
  /* -webkit-box-shadow: 0 3px 3px 0 rgb(0 0 0 / 30%);
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 30%); */
  min-width: 300px;

  z-index: 999;
  text-align: right;
  padding-right: 18px;
  top: 35px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #fff;
  margin-top: 15px;
  padding: 5px;
  font-size: 1.2rem;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #00a693;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  display: inline-block;
}

// /* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;

  border-radius: 5px;

  transition: all 0.5s ease-in-out;
}

// .dropdown-content-wrapper {
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
// }

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  color: #fff;
  background-color: #00a693;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.Shop_Cart span svg {
  font-size: 2.3rem !important;
  // margin: 0px 6px 1px 4px;
  color: #fff;
}

.Shop_Cart span {
  font-size: 1.3rem;
  // margin: 0px 6px 1px 4px;
}

.Shop_Cart_bg {
  background-color: #9e0b0f;
}

/* Slider */
.slider_top {
  background-image: url(../images/header.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  height: 250px;
  top: 15px;
  color: #fff;
  z-index: 0;
}

.Slider_Title {
  margin: 0 0 15px 0 !important;
}

.btn_Buy {
  font-size: 1.3rem !important;
}

.Main_content {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 10;
  background: #fff;
  margin-top: 0;
  margin-bottom: 0;
  padding: 20px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

/* Gift Cart  */
.Title_cart {
  text-align: center;
  font-size: 1.6rem !important;
  margin-top: 15px !important;
}

.Title_cart_head {
  text-align: center;
  margin-top: 20px !important;
  font-size: 2.8rem !important;
}

.cart_Heade a {
  text-decoration: none;
  color: #303030;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
  margin: 0 10px;
}

.cart_Heade a:hover {
  color: #ca1d1d;
}

.circle_Icon {
  background-color: #ca1d1d;
  width: 40px !important;
  height: 40px !important;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  text-align: center;
  display: flex;
  color: #fff;
  margin-left: 15px;
}

.circle_Icon svg {
  font-size: 20px !important;
}

.cart_Heade {
  display: flex;
  align-items: center;
}

.cart_Info {
  font-size: 1.4rem !important;
  margin: 10px 0 !important;
  line-height: 2.8rem !important;
  text-align: start;
}

.Cart_list li a {
  text-decoration: none;
  color: #303030;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.Cart_list li a:hover {
  color: #ca1d1d;
}

.icon_bg {
  width: 27px !important;
  height: 27px !important;
  font-size: 1.4rem !important;
  border-radius: 50px;
  line-height: 20px;
  text-align: center;
  vertical-align: center;
  margin-right: 15px;
}

.Nintendo {
  color: #ff7c00;
}

.ViewALl {
  color: #ca1d1d;
}

.play {
  color: #022264;
}

.carts {
  margin-top: 45px;
}

/* Gift cart */
.border_title_small {
  border: 1px solid #ca1d1d;
  width: calc(100% - 400px);
  position: absolute;
  margin-top: 15px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.border_title_medium {
  border: 1px solid #ca1d1d;
  width: calc(100% - 500px);
  position: absolute;
  margin-top: 3px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Page_Title_Heade {
  position: relative;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  h3{
        flex:1
  }
  hr{
    flex:2.5
  }
}

.border-top-1 {
  border-bottom: 2px solid #c91b00;
  display: block;
  margin-right: 15px;
  width: 65%;
  position: absolute;
  right: -28px;
}

.border-top-2 {
  border: 1px solid #ca1d1d;
  width: calc(100% - -28px);
  position: absolute;
  margin-top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.border-top-3 {
  border-bottom: 2px solid #c91b00;
  display: block;
  width: 100%;
  margin-top: 11px;
}

.border-top-4 {
  border-bottom: 2px solid #c91b00;
  display: block;
  width: 65%;
  margin-top: -10px;
}

@media only screen and (min-width: 1200px) {
  .Page_Titl_xl {
    display: block;
  }
  .Page_Titl_lg {
    display: none;
  }
  .mobileView {
    display: none;
  }
  .DefulatView {
    display: block;
  }
}

@media only screen and (max-width: 1024px) {
  .Page_Titl_xl {
    display: none;
  }
  .mobileView {
    display: block;
  }
  .DefulatView {
    display: none;
  }
  .page_titl_lg {
    display: block;
  }
}

@media only screen and (max-width: 992px) {
  .Page_Titl_xl {
    display: none;
  }
  .page_titl_lg {
    display: block;
  }
}

.Work_Section p {
  line-height: 2.7rem;
}

.Title_Size {
    font-size: 1.6rem !important;
    z-index: 20;
    color: #383737;
    font-weight: normal;
    position: absolute;
    padding: 5px 0 0 25px;
    right: 0;
    bottom: 16px;
}
.Page_Titl_lg {
  padding: 0 5px;
}
.Date {
  margin-top: 20px;
}

.review_P {
  line-height: 24px !important;
}

/* cart Games */
.Card_img {
  width: 100%;
}

.Card_media {
  transition: all 0.3s ease-in-out;
}
.Card_media_top_container {
  background-color: #F9F8F8;
  padding: 5px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 4px 4px 8px -3px rgb(0 0 0 / 44%);}
.Card_media:hover {
  opacity: 0.8;
  transition: opacity all 0.3s ease-in-out;
  transform: scale(1.02);
}

.Cart_Game_head {
  position: relative;
 
}
.country_flag {
  position: absolute;
  top: -8px;
  left: 10px;
}
.Cart_link {
  text-decoration: none;
  color: #333;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  font-weight: 100;
}

.Cart_link:hover {
  color: #00c9ac;
  transition: all 0.3s ease-in-out;
}

.Cart_list_items h3 {
  text-align: center;
  padding: 10px 0;
  margin-bottom: 20px;
}
.productName {
  margin-top: 5px;
}
.Cart_list_items.btn {
  display: flex;
  padding-bottom: 0;
}
.Cart_list_items {
  
  .price {
    text-align: left;
    color: #007d6d;
  }
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.cartBtn {
  flex: 1;
  position: absolute;
  bottom: 8px;
  right: 8px;
  left: 8px;
  .btn_buy_c {
    display: flex;
    flex: 2;
    
  }
}

// &_cartIconNum {

//   background-color: transparent !important;
//   position: relative;
//   @media (max-width: 500px) {
//     button {
//       position: relative;
//       // width: 100% !important;
//       margin: 0 !important;

//     }

//   }

// }

.Cart_list_items.btn .btn_add_Cart {
  //background-color: #00C9AC;
  color: #00c9ac !important;
  border: 2px solid #00c9ac;
  transition: all 0.1s ease-in-out;
  // border-radius: 50%;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.26);
    cursor: initial;
  }
}

.Cart_list_items.btn .btn_add_Cart svg {
  font-size: 18px !important;
  color: #fff;
}

.Cart_list_items.btn .btn_buy {
  flex: 4;
}

.Cart_list_items.price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Cart_list_items.reviews {
  justify-content: center;
}

.orginal p {
  position: relative;
}

.orginal p::before {
  content: "";
  position: absolute;
  border: 1px solid #c91b00;
  width: 45px;
  margin: 8px 0 0 0;
}

.now p {
  color: #c91b00;
}

.rating {
  color: #ffa200;
}

/* info  */

.bg_Sage {
  background: #27343f;
  padding: 25px;
  min-height: 500px;
}

.bg_Bulk {
  background: #374754;
  min-height: 500px;
  padding: 25px;
}

.Bulk_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 30px;
}

.Bulk_head img {
  width: 66px;
}

.Bulk_head h3 {
  color: #fff;
}

.product-container {
  background-color: #f2f2f2;
  padding: 20px;
  padding-top: 80px;
  position: absolute;
  left: 0;
  right: 0;
  top:0;
  bottom: 140px;
  overflow: scroll;
  padding-bottom: 5px;
}
.priceSlider {
  display: flex;
  justify-items: center;
  align-items: center;
}

.Add_To_cart {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1em;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .counter{
    max-width: 170px;
  }
}

.Info_Body p {
  color: #dcdcdc;
  font-weight: normal;
  line-height: 28px;
  text-align: start;
  margin-top: 20px;
}

.Info_Body button {
  margin-top: 20px;
  font-size: 17px;
  border-radius: 19px;
}

.Info_Body {
  display: flex;
}

.info_right_side {
  flex: 1;
}

.info_left_side {
  flex: 3;
}

.info_right_side p {
  font-weight: bold;
  text-align: center;
  font-size: 40px !important;
}

.info_right_side span {
  font-weight: 500;
  color: #fff;
  display: block;
  text-align: center;
  font-size: 15px !important;
}

.Work_Section {
  text-align: center;
  margin-top: 25px;
}

.Work_Section h3 {
  margin-bottom: 12px;
  color: #333;
}

.Work_Section {
  line-height: 30px !important;
}

.Work_Section img {
  max-width: 100%;
  height: 120px;
}

.Rviews_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.Card_Review_head {
  text-align: center;
  margin-top: 30px;
  background-color: #ebebeb !important;
}

// footer {
//   position: absolute;
//   bottom: 0;
//   width: 100%;
// }
// .footer_container {
// }
.top_footer {
  background-color: #ca1d1d;
  padding: 10px;
}

.Footer_align {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer_list {
  display: flex;
}

.footer_list li {
  font-size: 1.2rem !important;
  background: #ab1700;
  color: #fff;
  margin-right: 5px;
  border-radius: 5px;
  margin-top: 15px;
  min-width: fit-content;
}

.footer_Delivery {
  color: #fff;
  text-align: right;
  font-weight: 500 !important;
  flex-basis: 300px;
}

.choose_lang {
  color: #fff;
  text-align: center;
}

.Footer_pay {
  flex-basis: 300px;

  p {
    color: #fff;
    margin-bottom: 15px;
  }
}

.footerBg {
  //background-image: url(../images/ft-background.jpg);
  //background-repeat: repeat;
  //background-position: center bottom;

  background-color: #222831;
  padding-top: 40px;

  svg {
    margin-bottom: -6px;
  }
}

.border_rev {
  border-bottom: 1px solid #cac4c4;
  padding: 10px 0 13px 0;
}

/* mobil nav header */

.mobile_nav_header {
  position: sticky;
  background-color: #ffffff;
  padding: 5px 0;
  top: 0;
  width: 100%;
  height: 55px;
  z-index: 99;
  line-height: 3px;
}

.select_Lang {
  width: 150px;
  margin-top: 10px;
  background: transparent;
  border: 1px solid #ab1700;
  border-radius: 5px;
  padding: 5px;
}

.Footer_list_menu > h3 {
  font-size: 2.1rem;
  margin-bottom: 10px;
  font-weight: 300;
  color: #aaa;
  margin-top: 0.2rem;
  line-height: 21px;
  text-align: start;
}

.Footer_list_menu h3:nth-child(2) {
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 13px;
}

.Footer_list_menu ul li a {
  font-size: 1.4rem;
  color: #666;
  margin-bottom: 2px;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  display: inline-block;
}

.About_Gift {
  color: #666;
  line-height: 2.5rem !important;
  text-align: justify;
}

.item_footer {
  li > a {
    color: #faf8f9 !important;

    &:hover {
      color: #fff !important;
    }
  }

  li > p {
    color: #faf8f9 !important;
  }
}

.Footer_list_menu ul li a:hover {
  color: #fff3cd !important;
}

.Suport_Team {
  color: #faf8f9 !important;
}

@media (max-width: 576px) {
  .Suport_Team {
    font-size: 13px !important;
  }
  .About_Gift {
    font-size: 13px !important;
  }
  .new_G {
    font-size: 13px !important;
  }
}

@media (max-width: 768px) {
  .Suport_Team {
    font-size: 14px !important;
  }
  .About_Gift {
    font-size: 14px !important;
  }
  .new_G {
    font-size: 14px !important;
  }
}

.Support_link {
  color: #222 !important;
}

.Support_link:hover {
  color: #c91b00 !important;
  display: block;
}

.contact_phone {
  display: block;
  color: #fff;
  font-size: 1.4rem !important;
}

.new_G {
  font-size: 1.6rem !important;
}

.Social_footer a {
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50%;
  margin-right: 10px;
}

.Social_footer {
  display: flex;
  margin-top: 17px;
}

.Social_footer .Insta {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}

.Social_footer .Insta svg {
  color: #fff;
  font-size: 2.6rem;
}

.FaceBook {
  background: #3b5998;
}

.FaceBook svg {
  color: #fff;
  font-size: 2.6rem;
}

.Gif_Hover {
  transition: 0.3s ease-in-out;
}

.Gif_Hover:hover {
  transition: 0.3s ease-in-out;
  margin-top: -4px;
}

/* media Size */
@media (min-width: 770px) {
  .Cart_list_items.btn .btn_buy {
    font-size: 1.3rem !important;
  }
}

@media (max-width: 770px) {
  .Cart_list_items.btn .btn_buy {
    font-size: 1.3rem !important;
  }
  .Gif_lg_size {
    display: block;
  }
  .Gif_xs_size {
    display: none;
  }
}

@media (max-width: 575px) {
  .Cart_list_items.btn .btn_buy {
    font-size: 1.6rem !important;
  }
  .choose_lang {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .Gif_lg_size {
    display: none;
  }
  .Gif_xs_size {
    display: block;
  }
  .Gif_xs_size > button {
    font-size: 12px !important;
  }
  .select_Lang {
    text-align: right;
  }
}

@media (max-width: 430px) {
  .Cart_list_items.btn .btn_buy {
    font-size: 14px !important;
    padding: 0px;
    font-weight:lighter;
  }
  .Slider_Title {
    font-size: 1.4rem !important;
  }
  .btn_Buy {
    display: block !important;
    width: 100%;
    margin-top: 80px !important;
  }
  .Title_cart_head {
    font-size: 1.7rem !important;
  }
  .Title_cart {
    font-size: 1.3rem !important;
  }
  .cart_Heade {
    font-size: 1.6rem !important;
  }
  .Title_Size {
    font-size: 1.4rem !important;
  }
  .Bulk_head h3 {
    font-size: 1.6rem !important;
  }
  .Info_Body p {
    font-size: 1.4rem !important;
  }
  .Rviews_head h2 {
    font-size: 1.4rem !important;
    margin-bottom: 20px;
  }
  .makeStyles-GifT_Gallery-7 .Rviews_head h2 span {
    font-size: 1.4rem !important;
  }
}

.GifT_Gallery {
  grid-template-columns: auto auto auto;
}

@media (max-width: 800px) {
  .GifT_Gallery {
  grid-template-columns: auto auto auto;
  }
  .Info_Body {
    display: block;
  }
  .info_right_side {
    border: unset;
  }
  .Info_Body button {
    display: block !important;
    width: 100%;
    font-size: 12px !important;
  }
}

@media (max-width: 380px) {

  .price_size {
    span,
    p {
      font-size: 10px;
    }
  }

  .Cart_list_items.btn .btn_buy {
    font-size: 10px !important;
  }
  .GifT_Gallery {
    grid-template-columns: auto auto auto;
  }
  .Gif_Hover {
    width: 130px;
  }
  .orginal {
    font-size: 10px;
  }
  .Cart_list_items.reviews {
    font-size: 9px;
  }
}

@media (max-width: 330px) {
  .GifT_Gallery {
    grid-template-columns: auto auto auto;
    grid-gap: 15px;
    margin-left: 2px;
  }
  .Gif_Hover {
    width: 130px;
  }
  .Cart_list_items.btn .btn_buy {
    font-size: 10px !important;
  }
}

.dropdown_mega {
  float: right;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dropdown_mega-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 10px;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1200;
  overflow: hidden;

  background-color: #00a693;
  border-radius: 0 0 3px 3px;

  margin-top: 20px;
  text-align: right;

  .row {
    //justify-content: space-between;

    border-radius: 3px;

    h3 {
      text-align: start;
    }
  }
}

.dropdown_mega:hover .dropdown_mega-content {
  display: block;
  margin-top: 15px;
  z-index: 1500;
  top: 90px;
}

.column {
  width: 15%;
  padding: 10px;

  height: 100px;
}

.column a {
  float: none;
  color: #fff;
  padding: 5px;
  text-decoration: none;
  display: inline-block;
  text-align: right;

  font-size: 1.2rem;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.column a:hover {
  background-color: #00a693;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

//-------search page
.SearchPage_wrapper {
  background-color: #e4e4e4;
}

$local-suggestion-transition: 0.2s;

@mixin local-input-focus-state() {
  @if ($header-type == white) {
    .search__input:focus {
      @content;
    }
  } @else {
    .search__input:focus,
    &.search--has-suggestions.search--suggestions-open .search__input {
      @content;
    }
  }
}

.search__body {
  width: 100%;
  min-width: 200px;
  width: 200px;
  z-index: 1001;
  position: relative;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  border-radius: 100px;

  outline: none;

  &:focus-within {
    width: 300px;
    border-radius: 10px;
  }

  @media (max-width: 1027px) {
    width: 100%;
    min-width: 200px;
    &:focus-within {
      width: 100%;
      min-width: 200px;
    }
  }
}

.search__form {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 10px;
  //border: 2px solid #cecece;

  z-index: 10;
  @media (max-width: 1027px) {
    margin: 0 10px;
  }


  .search__input {
    width: 100%;
    border: none !important;
    color: #333 !important;
    outline: none;
    font-size: 14px !important;

    &:focus {
      //border: none !important;
    }
  }
}

.search__suggestions {
  position: absolute;
  z-index: -1;
  background-color: #fff;
  width: 105%;
  padding-top: 40px;
  left: -2.5%;
  top: -10px;
  display: flex;
  min-height: 100px;
}

.suggestions__list {
  padding: 10px 0 !important;
  width: 100%;
}

.suggestions__item {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px !important;
  margin: 5px;

  &:hover {
    background-color: rgba(52, 58, 64, 0.1);
  }
}

.suggestions__item-info {
  margin: 0px 10px;
  font-weight: bold;
}

.product-image__body {
  max-height: 100%;
}

.suggestions__item-image {
  img {
    height: 50px;
  }
}

//mobile search
.mobileSearch_container {
  width: 100vw;
  position: absolute;
  top: 0;
  height: 64px;
  background-color: #fff;
  transform: translateY(-65px);
  padding: 10px 0px;
  padding-top: 30px;

  transition: transform 0.2s ease-in-out;

  z-index: 5;

  &_show {
    transform: translateY(0);
  }
}

.search_closeBtn {
  position: absolute;
  z-index: 2000;
  font-size: 25px !important;
  left: 10px;
  top: 2px;
  cursor: pointer;
}

// review slider

.carousel-container {
  direction: ltr;
  margin: 0 10px;
  margin-top: 25px;
  max-width: 90vw;

  .Reviews_review {
    margin-top: 15px;

    background-color: rgb(235, 235, 235);

    h3 {
      border-bottom: 1px solid #999;
      color: #ab1700;
    }
  }

  @media (max-width: 600px) {
    max-width: 80vw;
  }
}

//nav page
.NavPage {
  background-color: #e4e4e4;
}
