.Login_Container {
  position: relative;
  // display: grid;
  display: flex;
  justify-content: center;
  height: 80vh;
  align-items: center;
}
.TabPanel span{
  font-size: 13px;
}
.login_center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

@media (max-width: 768px) {
  .form_control_Center {
    width: 70% !important;
    margin-top: 30px !important;
  }
  .form_control_Center_Register {
    width: 70% !important;
    margin-top: 30px !important;
  }
  .Login_Container {
    margin-top: 30px;
  }
}

@media (max-width: 576px) {
  .resendCode > span > div {
    font-size: 11px;
  }
  .form_control_Verify {
    width: 96% !important;
  }
}

@media (max-width: 768px) {
  .resendCode > span > div {
    font-size: 11px;
  }
  .form_control_Verify {
    width: 60% !important;
  }
}

@media (max-width: 425px) {
  .Register_link {
    margin-top: 15px;
  }
  .resendCode > span > div {
    font-size: 10px;
  }
  .form_control_Verify {
    width: 96% !important;
  }

  .form_control_Center {
    width: 90% !important;
    margin-top: 30px !important;
  }

  .form_control_Center_Register {
    width: 90% !important;
    margin-top: 30px !important;

  }
  .SignIn {
    font-size: 12px !important;

  }

  .input {
    width: 2ch;
  }
  .container_i {
    padding: unset !important;

  }
}

.container_i {
  padding: 16px;
  display: flex;
  direction: ltr;

  justify-content: space-between;
}

.form_control_Verify {
  background-color: rgba(0, 0, 0, 8);
  padding: 20px;
  width: 50%;
  margin: 0 auto;
}
.form_control_Verify.login_ratio{
  padding:unset !important;
}
.input {
  direction: ltr;
  width: 40px;
  padding: 8px;
  border-radius: 3px;
  font-size: 40px;
  text-align: center;
  color: #222!important;
  margin-right: 12px;
  background: #eee;
  border: none;
  border-bottom: 1px solid rgb(106, 106, 106);
  outline: none;
  text-transform: uppercase;
}

.AuthCode {
  padding: 5px;
  margin: 20px 19px 8px 0 !important;
  display: block;
  position: absolute;
}

.form_control_Center {
  padding: 20px;
  width: 50%;
  background: rgba(1, 1, 1, 8);
  border-radius: 5px;
  margin: 0 auto;
  @media (max-width: 743px) {
    width: 90%;
  }
}

.Data_Time {
  height: 50px;
  background: #262d36;
  border: 1px solid #262d36 !important;
}

.Register_Container {
  display: grid;
  place-items: center;
  margin-top: 40px;
}

.Register_link {
  color: #ca1d1d;
  display: flex;
  justify-content: left;
}

.form_control_Center_Register {
  padding: 20px;
  width: 50%;
  position: static;
  margin-bottom: 90px;
  background: rgba(1, 1, 1, 0.2);
  border-radius: 5px;
}

.Approve_btn {
  width: 100% !important;
  display: block;
  border-radius: 13px !important;
  height: 45px;
  font-size: 1.4rem !important;
  margin-top: 30px !important;
  cursor: pointer;
  width: 200px;

  &:disabled {
    background-color: #555 !important;
  }
}

.custome_input {
  margin-top: 30px !important;
  background: #262d36;
  color: #fff;
  font-size: 17px !important;
  border-radius: 5px;
}

.Date_input {
  width: 100% !important;
  border: none;
  height: 55px;
  outline: none;
  border-radius: 5px;
  background-color: #262d36;
  text-align: right;
  padding-right: 5px;
}

/* Date Picker */

.calendarButton {
  background: #ca1d1d !important;
}

.calendarContainer {
  background: #262d36 !important;
}

.Or_whith {
  position: relative;
}

.Or_whith::before {
  content: "یا";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  color: #fff;
  margin: 0 auto;
  padding: 5px;
  z-index: 99;
  margin-top: 16px;
}

.Or_whith span {
  border: 1px solid #232931;
  position: absolute;
  width: 100%;
  right: 0;
  top: 17px;
}

.S_G {
  height: 45px !important;
  width: 100%;
  margin-top: 30px !important;
  background: #232931 !important;
  color: #fff !important;
  font-size: 13px !important;
  border-radius: 13px !important;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: #aaa;
}

.value {
  font-size: 40px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}