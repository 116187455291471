.user__Profile {
  list-style-type: none;
  text-align: start;
  padding: 0;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  li {
    font-size: 1.3rem;
    margin-top: 15px;
    position: relative;
    //
    //&:after {
    //  content: "";
    //  position: absolute;
    //  background-color: #ca1d1d;
    //  width: 5px;
    //  height: 16px;
    //  border-top-left-radius: 3px;
    //  border-bottom-left-radius: 3px;
    //  right: 0;
    //  transform: translate(15px, 1px);
    //  -moz-transform: translate(15px, 1px);
    //  -o-transform: translate(15px, 1px);
    //  -ms-transform: translate(15px, 1px);
    //}
    span {


    }
  }
}

.Date_input.profile {
  margin-top: 25px;
}

.form_Profile {
  width: 80%;
  margin: 0 auto;
}

//order details
.OrderDetails {
  background-color: #fff;
  min-height: 200px;
  padding: 10px;
  margin-bottom: 10px;
  overflow: auto;

  h3 {
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid rgb(165, 165, 165);
  }

  table {

    padding: 10px;


    tr {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    th,
    td {
      display: inline-flex;
      justify-content: center;
      width: 100%;
      padding: 10px 5px;
    }

    th {
      text-transform: capitalize;
    }

    td {
      font-size: 12px;
    }
  }
}

.orderData_mainData {
  table {
    border-bottom: 1px solid rgb(165, 165, 165);

    tbody {
      tr {
        margin-top: 10px;
      }
    }
  }
}

.orderData_productsData {
  h3 {
    padding: 15px 0;
    margin-top: 30px;
  }

  table {

    overflow-x: scroll;

  }

  tbody {
    tr {
      height: 200px;

      margin: 10px 0;

      img {
        max-height: 200px;
        padding: 10px 0;
      }
    }
  }
}

.pagination {
  margin-top: 10px;
}

.orderData_container {
  max-width: 100%;
}