@font-face {
  font-family: "IRANSans";
  src: url("../Fonts/IRANSans.eot") format("embedded-opentype"),
    url("../Fonts/IRANSans.woff2") format("woff2"),
    url("../Fonts/IRANSans.woff") format("woff"),
    url("../Fonts/IRANSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  box-sizing: border-box;
}
body {
  font-family: "IRANSans";
  font-size: 1.2rem;
  margin: 0;

}


#root {
  height: 100vh;
  & > div {
    height: 100%;

  }
}
button {
  outline: none !important;
}

.Gird_Container {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 12rem 1fr 5rem;
  height: 100%;
}

header {
  grid-area: header;
}
main {
  grid-area: main;
}
footer {
  grid-area: footer;
}
input {
  font-size: 19px !important;
  color: #fff !important;
}
.opacity_8 {
  opacity: 1;
  background: #b14242 !important;
  color: #fff !important;
}

@media (max-width: 1024px) {
  .Gird_Container {
    grid-template-rows: 5rem 1fr 5rem;
  }
}

.dz-scroll {
  position: relative;
}
.swal2-container {
  direction: rtl !important;
  font-size: 13px !important;
}
