.filter_input {
  background: #262d36 !important;
  color: #fff !important;
  //direction: rtl !important;
}

.filte_btn {
  font-size: 25px !important;
}

.filter_search {
  font-size: 20px !important;
}

.Top_BackGround {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #333;
  min-height: 80px;
  background-position: center center;
  background-color: #fff;
  // background-image: url(../../assets/images/categoryHead.jpg);
  background-repeat: no-repeat;
}

.body_Categories {
  margin-bottom: 2px;
  box-shadow: 0 -5px 10px #aaaaaa;
  border-radius: 16px 16px 0 0;
  padding: 20px;
}

.Accordion_bg {
  box-shadow: unset !important;
}

.filter_list {
  background: #e4e4e4;
  padding: 15px;
  width: 100%;
}

.filter_list li {
  font-size: 13px;
}

.img_category {
  max-height: 337px;
  display: block;
  box-shadow: 2px 2px 8px #0505058e;
  border-radius: 1.5em;
}

.fast_delivery {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  border: 1px solid #dfdfdf;
  padding: 5px;
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  background-color: #ebebeb;
}

.del_Content {
  padding: 25px;
}

.Add_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  border-radius: 5px;
  position: relative;
  margin: 0 auto;
  .currency_code{
    font-size: 10px;
  position: absolute;
  right: 50px;

  } 
  span {
    font-size: 14px;
  }

  .Add_item_num {
    input{
     text-align: center;
     color:#050505!important;
    }
    font-weight: bold;
    color: #050505;
    letter-spacing: 1px;
    padding-top: 3px;
  }
}

.Add_item > button {
  border: 1px solid #ccc;
  margin-right: 5px;
  outline: unset;
  margin-left: 5px;
  padding: 0;
  font-size: 20px;
}

.btn_price {
  font-size: 16px !important;
  margin-top: 15px !important;
  display: block !important;
}

.Categorys_sections {
  border-top: 1px solid #ccc;
  margin: 20px 0 20px 0;
}

.Category_SIde {

}

.Related_icons {
  color: #fff !important;
  background-color: #ca1d1d;
  width: 30px !important;
  height: 30px !important;
  //margin: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.Related_head {
  display: flex;
  align-items: center;
}

.Related_title {
  margin: 0px 6px;
}

.Related_icons svg {
  transform: rotate(180deg);
}

.Related_Body {
  margin-top: 20px;
}

.Related_sec {
  display: flex;
  border-top: 1px solid #ccc;
  margin-top: 15px;
}

.Related_sec p {
  margin-top: 15px;
}

.Related_sec img {
  margin: 15px 8px 0;

}

.Details {
  margin-top: 15px;
  display: flex;
}

.Details h3 {
  color: #303030;
  margin-top: 10px;
  font-weight: bold;
  border-bottom: 2px solid #c91b00;
  padding-bottom: 6px !important;
  margin-bottom: 15px !important;
  display: inline-block;
}

.Details_info {
  line-height: 1.7 !important;
  text-align: right;
  max-width: 700px;
}

// .Category_searchWrapper {
//   display: flex;
// }
@media (max-width: 768px) {
  .MuiTypography-h2 {
    font-size: 18px !important;
  }
}

.product_description {
  max-width: 100%;
  overflow: hidden;

  img {
    max-width: 100% !important;
    height: 150px;
    object-fit: cover;
  }
}

.pagination {
  justify-content: flex-end;
}

///Error Page

.error_page {

  h4 {
    color: #fff;
    white-space: nowrap;
    font-size: 18px;
  }

  p {
    color: #ce7979;
    font-size: 16px;
  }

  button {
    font-size: 13px;
    margin-top: 20px;
  }
}

.bg-404 {
  padding: 50px;
}

.h404 {
  color: #ca1d1d;
  font-size: 40px;
  text-align: center;
}


.bg_ArrowUpward {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ArrowUpward {
  color: #fff;
  font-size: 15px;
}

.product_description > p, pre, div, span {
}

.product_description > img {
  width: 150px;
  height: 150px;

}